@use 'color'

table
    border-collapse: collapse
    width: 100%

    tr
        background: color.$white
        transition: background .2s ease

        &:nth-child(even)
            background: color.$whiteish

        td, th
            padding: .75em 1em
            text-align: left
            border: color.$border
            word-wrap: break-word
            max-width: 1px

        th
            background: color.$main
            color: color.$white
            letter-spacing: .04em
            padding: 1em 1em
            user-select: none
            cursor: pointer

            .th-title
                display: inline-flex
                align-items: center

        &.delete
            background: color.$danger

            td
                color: color.$white
