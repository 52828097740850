@use 'color'

.input
    position: relative
    display: flex
    align-items: center

    .material-icons
        position: absolute
        left: .5em

    input
        border: none
        padding: 1em 1.25em
        border-radius: 7px
        width: 100%
        outline: none
        box-shadow: 0 3px 6px rgba(0,0,0,0.16), 0 3px 6px rgba(0,0,0,0.23)
        transition: all .3s ease

        &[type=text]:focus
            box-shadow: 0 10px 20px rgba(0,0,0,0.19), 0 6px 6px rgba(0,0,0,0.23)

        &.icon
            padding-left: 2.8em
