@use 'color'

.adder
    width: 100%
    max-width: calc(min(100%, 400px) - 32px)
    position: fixed
    bottom: 1em
    right: 1em
    background: color.$white
    cursor: default
    border-radius: 7px
    justify-content: flex-start
    align-items: flex-start
    box-shadow: 0 3px 6px rgb(0 0 0 / 16%), 0 3px 6px rgb(0 0 0 / 23%)
    transition: all .2s ease
    overflow: hidden

    &:not(.expanded)
        border-radius: 50%
        width: 68px

        &:hover
            box-shadow: 0 10px 20px rgb(0 0 0 / 19%), 0 6px 6px rgb(0 0 0 / 23%)

    .expander
        width: 68px
        height: 68px
        display: flex
        justify-content: center
        align-items: center
        padding: 1em
        border-radius: 50%
        background: color.$main
        color: color.$white
        cursor: pointer

        .material-icons
            font-size: 2.25em

    .form

        .header
            width: 100%
            display: flex
            flex-direction: row
            justify-content: space-between
            align-items: center
            background: color.$main
            color: color.$white
            font-size: 1.17em
            padding-left: 1.25em

            button
                font-size: 1.17em
                background: none
                border: none
                width: 3em
                height: 3em
                color: color.$white
                cursor: pointer

        .body
            padding: 1.25em

            & > *
                margin-bottom: 1.25em

            .center
                display: flex
                flex-direction: row
                justify-content: center

                &:last-child
                    margin-bottom: 0

            button.confirm
                border: none
                background: color.$main
                color: color.$white
                border-radius: 7px
                padding: 0 1em
                height: 50px
                font-family: 'Roboto', sans-serif
                font-size: 1.17rem
                cursor: pointer
                outline: none
                transition: all .3s ease
                box-shadow: 0 3px 6px rgb(0 0 0 / 16%), 0 3px 6px rgb(0 0 0 / 23%)
                margin-top: 1em
