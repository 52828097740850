@use 'color'

*
  box-sizing: border-box
  font-family: 'Open Sans', sans-serif

.main
  padding: 16px
  padding-bottom: 100px
  background: color.$bg
  width: 100%

  .title
    font-family: 'Roboto', serif

  .logo
    display: flex
    flex-direction: row
    justify-content: center
    margin-bottom: 2em

    img
      width: 80px

  .search
    margin-bottom: 1em
